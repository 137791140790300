import Vue from 'vue';
import Vuelidate from 'vuelidate';
import Turbolinks from './turbolinks';
import VueScreen from 'vue-screen'; // https://github.com/reegodev/vue-screen
import i18n from './i18n';
import customPlugins from '../plugins/custom';
import './components';
import '../plugins/sentry';
import '../directives/click-outside';
import * as Sentry from '@sentry/vue';

Vue.use(VueScreen);
Vue.use(Vuelidate);
Vue.use(customPlugins);

Vue.config.productionTip = false;

Vue.prototype.$turbolinks = Turbolinks;
Vue.prototype.$googleMapsApiKey = 'AIzaSyBnmZ7R1iDg0_lrlqOiollVgVTfTRTKEDM';

Vue.directive('click-outside', {});

window.gm_authFailure = function(e) {
    Sentry.captureException('Google Maps JavaScript API error');
}

document.addEventListener('turbolinks:load', () => {
    new Vue({
        el: '[data-behavior="vue"]',
        i18n,
        mounted() {
            i18n.locale = this.$el.attributes.locale?.value;
        },
    });
});

document.addEventListener('turbolinks:before-render', function () {
    window.zEACLoaded = undefined;
});
