import Vue from 'vue';
import * as Sentry from '@sentry/vue';

const isFetchError = (event) => {
  return !!event.breadcrumbs?.find((ev) => {
    return ev.category === 'fetch' &&
      (ev.data?.url || '').match(/(monitor\.clickcease\.com)|(chrome-extension)/) &&
      (ev.data?.status_code >= 400 || ev.level === 'error');
  });
};

// Disable sentry to check if it provides any value
if (false && ['staging', 'production'].includes(process.env.NODE_ENV)) {
  Sentry.init({
    Vue,
    dsn: 'https://4eded25292b54397b61e16b15d890096@o61191.ingest.sentry.io/5547324',
    integrations: [
      new Sentry.BrowserTracing({
        shouldCreateSpanForRequest(currentUrl) {
          return ['https://staging.yago.be', 'https://www.yago.be'].some(
            (allowedUrl) => new URL(currentUrl).origin === new URL(allowedUrl).origin,
          );
        },
      }),
    ],
    tracesSampleRate: 0.1, // 10% of the transaction events will be forwarded to Sentry
    allowUrls: ['https://staging.yago.be', 'https://www.yago.be'],
    denyUrls: ['https://seraphin.be/cdn-cgi/'],
    environment: process.env.NODE_ENV,
    ignoreErrors: [
      'Can\'t find variable: fbq',
      'Error: Network Error',
      'Failed to execute \'replaceState\' on \'History\'',
      'Non-Error promise rejection captured with value: Object Not Found',
      'QuotaExceededError:',
      'ResizeObserver loop limit exceeded',
      'SecurityError: The operation is insecure.',
      'TypeError: Illegal invocation',
    ],
    beforeSend(event) {
      try {
        if (isFetchError(event)) return null;
      } catch (e) {
        return event;
      }
      return event;
    },
  });
} else {
  console.debug('Not using Sentry, environment is', process.env.NODE_ENV);
}
