export default {
  install (Vue) {
    // Add $plugin instance method directly to Vue components
    Vue.prototype.$merge = (obj1, obj2) => recursiveMerge(obj1, obj2);
    Vue.prototype.$toCamel = (string) => toCamel(string);
    Vue.prototype.$toSnake = (string) => toSnake(string);
    Vue.prototype.$toTitleCase = (string) => toTitleCase(string);
    Vue.prototype.$ageDiff = (date, comparisonDate) => ageDiff(date, comparisonDate);
    Vue.prototype.$calculateAge = (string) => calculateAge(string);
    Vue.prototype.$localStorage = safeLocalStorage();
  },
};

const toCamel = (string) => {
  return string.replace(/[_][a-z\d]/ig, (k) => k.toUpperCase().replace('_', ''));
};

const toSnake = (string) => {
  return string.replace(/[A-Z]|\d+/g, (k) => `_${k.toLowerCase()}`);
};

const recursiveMerge = (obj1, obj2) => {
  for (const key in obj1) {
    if (obj1[key] && obj2[key] && typeof obj1[key] === 'object' && !Array.isArray(obj1[key])) {
      recursiveMerge(obj1[key], obj2[key]);
    }
    else {
      obj1[key] = obj2[key] == null || obj2[key] === '' ? obj1[key] : obj2[key];
    }
  }
};

const toTitleCase = (text) => {
  return text ? text.toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ') : null;
};

const safeLocalStorage = () => {
  /* eslint-disable no-unused-vars */
  const voidLocalStorage = {
    clear: () => null,
    getItem: (_key) => null,
    key: (_index) => null,
    removeItem: (_key) => null,
    setItem: (_key, _value) => null,
  };

  try {
    return localStorage || voidLocalStorage;
  } catch (e) {
    return voidLocalStorage;
  }
};

const ageDiff = (date, comparisonDate) => {
  if (!date || !comparisonDate) return null;

  const ageInMs = new Date(date) - new Date(comparisonDate);
  const msToYears = 365 * 24 * 60 * 60 * 1000;

  return (ageInMs / msToYears);
};

const calculateAge = (date, fullYear = false) => {
  if (!date) return NaN;

  const today = new Date();
  const birthyear = new Date(date);
  return fullYear ? ageDiff(today, birthyear) : today.getFullYear() - birthyear.getFullYear();
};

