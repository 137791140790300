import Vue from  'vue';

Vue.directive('clickoutside', {
  bind (el, binding) {
    el.__clickOutsideHandler = (event) => {
      if (path(event) && !path(event).includes(el)) {
        binding.value(event);
      }
    };

    const path = (e) => {
      try {
        return e.composedPath();
      } catch (error) {
        return composedPath(e.target);
      }
    };

    // Polyfill for IE and Edge
    const composedPath = (el) => {
      const path = [];
      while (el) {
        path.push(el);
        if (el.tagName === 'HTML') {
          path.push(document);
          path.push(window);
          return path;
        }
        el = el.parentElement;
      }
    };

    document.addEventListener('click', el.__clickOutsideHandler);
  },
  unbind (el) {
    document.removeEventListener('click', el.__clickOutsideHandler);
  },
});
